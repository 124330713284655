import { Authority } from '~/src/enums/Authority'
import { getClientUser } from '~/composables/queries/useClientUser'

interface AuthMeta {
  authority: Authority
}

export default defineNuxtRouteMiddleware(async (to) => {
  const supabaseUser = useSupabaseUser()
  const { $clientPosthog } = useNuxtApp()

  const { data: user, suspense } = getClientUser({
    params: {
      path: { id: supabaseUser.value?.id || '' },
    },
    query: {
      enabled: supabaseUser.value?.id !== undefined,
      staleTime: Infinity,
    },
  })

  if (supabaseUser.value) {
    await suspense()
  }

  const localePath = useLocalePath()

  const isDifferentRoute = (route: string) => to.path !== localePath(route)

  if (to.path === localePath('reset')) {
    return
  }

  if (!user.value) {
    return navigateTo(localePath({ name: 'sign-in', query: { redirect: to.path } }))
  }

  if ($clientPosthog) {
    $clientPosthog.identify(user.value.id, { email: user.value.email, user_id: user.value.id })
  }

  const authority: Authority = to.meta.auth ? (to.meta.auth as AuthMeta).authority as Authority : Authority.Completed

  const isSurveyCompleted = user.value && user.value.questionnaire

  if (isSurveyCompleted && authority === Authority.Completed) {
    return
  }

  if (!isSurveyCompleted && isDifferentRoute('survey')) {
    return navigateTo(localePath('survey'))
  }
})
